.display-table {
    margin: 0px 40px !important;
}

.table-item-score {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.table-item-at {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.standings-table {
    margin-bottom: 50px;
}