.container {
    flex-grow: 1;
}

.item-block {
    width: 100%;
}

.league-title {
    color: #C00 !important;
    padding-bottom: 5px;
    border-bottom: 1px solid #DDDDDD;
}

.page-title {
    font-style: italic;
    font-weight: bold !important;
}