/*      /styles/components/_header.scss     */

.header {

    background: $header-bg;
    margin-bottom: $size-xl;
    padding: $size-md;
    color: white;

    h1 {
        font-size: $size-lg;
        margin: 0;
    }

    h2 {
        color: $off-white;
        font-size: $size-md;
        font-weight: normal;
        margin: 0;
    }

    .headerlogo {
        height: 60px;
    }

    .nav-links {
        float: right;
        color: $prim-color;

        a {
            color: #000;
            text-decoration: none;
        }

        a:hover {
            color: $prim-color;
        }

        a:focus {
            color: #000;
            background: none;
        }
    }

    .dropdown-menu {
        font-size: 1.5rem;
    }

    .dropdown-item {
        padding: .25rem 2rem;
    }

    .menu-toggle-button {
        position: absolute;
        top: 8px;
        right: 0;
    }

    .menu-toggle-button:focus {
        outline: none;
    }

    .navbar-collapse {
        position: absolute;
        top: 8px;
        right: 0px;
        z-index: 1000;

        .navbar-nav {
            background: $prim-color;
            border-radius: 5px;

            a {
                padding: 10px 20px;
                color: #FFF;
            }
        }
    }

    @media (max-width: 992px) {
        .navbar-collapse {
            top: 55px;
        }
    }
}

@media(min-width: 480px) {
    .nav-links-view {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        list-style-type: none;
        padding-left: 0;
    
        li {
            flex: 1;
            text-align: center;
        }
    }

    .nav-links-menu-button {
        display: none !important;
    }
}

@media(max-width: 480px) {
    .nav-links-view {
        display: none;
    }

    .full-list {
        width: auto;
    }

    .nav-drawer-list {
        width: 250px;
    }
}

.nav-link {
    color: #FFF;
    text-decoration: none;
}

.nav-link-mobile {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    text-decoration: none;
    width: 100%;
    padding: 11px 26px;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'Roboto', "Helvetica", "Arial", sans-serif;
    line-height: 1.5em;
}

.header-logo {
    height: 25px;
}
