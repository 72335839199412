.errorPage {

    h1 {
        font-size: 40px;
        padding-bottom: 20px;
    }
}

.container-select-organization {
    h1 {
        font-size: 32px;
        padding-bottom: 20px;
    }
}

.organization-list-item {
    cursor: pointer;
    background: #DDDDDD;
    border-radius: 5px;
    border: 2px solid $prim-color;
    margin-bottom: 10px;
    padding: 20px;

    h2 {
        text-decoration: italic;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 5px;
    }
}

.organization-list-item:hover {
    background: $prim-color;
    border-radius: 5px;
    border: 2px solid $sec-color;
    margin-bottom: 10px;
    padding: 20px;

    h2 {
        text-decoration: italic;
        margin-bottom: 15px;
        color: #FFF;
    }

    p {
        margin-bottom: 5px;
        color: #FFF;
    }
}

.container-dashboard {

    .dashboard-noresults {
        text-align: center;
        padding: 0 10px 10px 10px;
        font-size: 18px;
    }

    .organization-info {
        // border-radius: 5px;
        // background-color: $off-white;
        padding: 0px 0px 0px 0px;
        margin-bottom: 20px;
        // border: 1px solid $prim-color;

        h1 {
            font-size: 40px;
            margin-top: 0px;
            padding-bottom: 3px;
            border-bottom: 2px solid $prim-color;
        }
    }

    .upcoming-events {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .group-updates {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }

    .member-updates {
        border-radius: 5px;
        background-color: #DDDDDD;
        padding: 40px 20px;
        margin-bottom: 20px;
        border: 1px solid $prim-color;

        h2 {
            font-size: 32px;
            margin-top: 0px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
    
}

.list-group-dashboard {

    .list-group-item {
        border-color: $prim-color;
        cursor: pointer;
    }

    .list-group-item:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .list-group-item:hover {
        background: $prim-color;
        color: $off-white;
    }
}

.btn-dashboard-window {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}