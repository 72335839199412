body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: $size-md;
    background: $body-bg;
}

h1 {
    font-size: 2rem;
}

button {
    cursor: pointer;
}

button:disabled {
    cursor: default;
}

.pull-right {
    float:right;
}