.modal_admin {
    position: absolute;
    max-width: 600px;
    padding: 40px;
    padding-top: 100px;
    margin: 0 auto;
    outline: none;

    .modal-title {
        margin-bottom: 15px;
    }

    .modal-description {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-error {
        color: $prim-color;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-success {
        color: #009900;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-member {
        background-color: #ffffff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        padding: 15px;
    }

    .modal-select {
        width: 100%;
    }

    .form-control {
        width: 100%;
    }

    .modal-button-bottom {
        margin-top: 15px;
    }

    .modal-select {
        width: 100%;
    }

    .modal-input-label {
        margin-left: 15px !important;
        margin-top: 10px !important;
    }

    .modal-datepicker {
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid $sec-color;
    }
}