// Variable Colors

$prim-color: #b31119;
$sec-color: #000000;
$off-white: #E1EFF6;
$header-bg: #DDDDDD;
$body-bg: #FFFFFF;
$list-bg: #97D2FB;
$purple: #4CB944;
$red: #900;

$size-sm: 1.2rem;
$size-md: 1.6rem;
$size-lg: 3.2rem;
$size-xl: 4.8rem;