.grid-container {
    padding: 20px 40px;
}

.grid-container-nopad-vert {
    padding: 0px 40px;
}

.grid-container-pad-bottom {
    padding: 0px 40px 20px 40px;
}

.button-schedule-left {
    float: left;
}

.button-schedule-selected {
    background: #3f51b5 !important;
    color: #FFFFFF !important;
}

.button-schedule-right {
    float: right;
}

.overflow-horz {
    overflow-x: auto;
}